import { TObjectType } from '@fto/lib/extension_modules/indicators/api/IndicatorInterfaceUnit'

export enum PaintToolNames {
    ptText = 'Text',
    ptNote = 'ptNote',
    ptHLine = 'ptHLine',
    ptAndrewsPitchfork = 'Andrews Pitchfork',
    ptEllipse = 'Ellipse',
    ptRectangle = 'Rectangle',
    ptTriangle = 'Triangle',
    ptVLine = 'vLine',
    ptFiboArc = 'Fibo Arc',
    ptFiboFan = 'Fibo Fan',
    ptFiboRetracement = 'Fibo Retracement',
    ptFiboTimeZones = 'Fibo Time Zones',
    ptFiboExtension = 'Fibo Extension',
    ptFiboChannel = 'Fibo Channel',
    ptPolyline = 'Polyline',
    ptRay = 'Ray',
    ptTrendLine = 'Trend Line',
    ptRegressionChannel = 'Regression Channel',
    ptExtPriceLabel = 'Price Label',
    ptPriceLabelRight = 'Price Label Right',
    ptPriceLabelLeft = 'Price Label Left',
    ptMeasure = 'Measure',
    ptRiskReward = 'Risk/Reward',
    ptRiskRewardBuy = 'Risk/Reward buy',
    ptRiskRewardSell = 'Risk/Reward sell',
    ptElliottWaveSymbolsImpulse = 'Impulse Elliott Wave',
    ptSign = 'Sign',
    ptArrowUp = 'Arrow Up',
    ptArrowDown = 'Arrow Down',
    ptThumbUp = 'Thumb Up',
    ptThumbDown = 'Thumb Down',
    ptCheck = 'Check',
    ptStop = 'Stop',
    ptElliottWaveSymbolsSimpleCorrection = 'Simple Correction Elliott Wave',
    ptElliottWaveSymbolsComplexCorrection = 'Complex Correction Elliott Wave',
    ptElliottWaveSymbolsIndefiniteStart = 'Indefinite Start Elliott Wave',
    ptBrush = 'Brush',
    ptGannBox = 'Gann Box'
}

export function objectTypeToName(objectType: TObjectType): string {
    switch (objectType) {
        case TObjectType.obj_Rectangle: {
            return PaintToolNames.ptRectangle
        }
        case TObjectType.obj_Ellipse: {
            return PaintToolNames.ptEllipse
        }
        case TObjectType.obj_Triangle: {
            return PaintToolNames.ptTriangle
        }
        case TObjectType.obj_VLine: {
            return PaintToolNames.ptVLine
        }
        case TObjectType.obj_HLine: {
            return PaintToolNames.ptHLine
        }
        case TObjectType.obj_TrendLine: {
            return PaintToolNames.ptTrendLine
        }
        case TObjectType.obj_Ray: {
            return PaintToolNames.ptRay
        }
        case TObjectType.obj_Polyline: {
            return PaintToolNames.ptPolyline
        }
        case TObjectType.obj_FiboRetracement: {
            return PaintToolNames.ptFiboRetracement
        }
        case TObjectType.obj_FiboTimeZones: {
            return PaintToolNames.ptFiboTimeZones
        }
        case TObjectType.obj_FiboArc: {
            return PaintToolNames.ptFiboArc
        }
        case TObjectType.obj_FiboFan: {
            return PaintToolNames.ptFiboFan
        }
        case TObjectType.obj_Text: {
            return PaintToolNames.ptText
        }
        case TObjectType.obj_TextLabel: {
            return PaintToolNames.ptExtPriceLabel
        }
        case TObjectType.obj_GannBox: {
            return PaintToolNames.ptGannBox
        }
        default: {
            return ''
        }
    }
}
