import { PaintToolNames } from '@fto/lib/charting/paint_tools/PaintToolNames'
import { ToolPropertiesMap } from '@fto/lib/charting/paint_tools/SpecificTools/types'
import { TCalcFrom } from '@fto/lib/charting/paint_tools/SpecificTools/ptRiskReward'
import { DelphiColors, TBrushStyle, TPenStyle } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { ColorHelperFunctions } from '@fto/lib/drawing_interface/ColorHelperFunctions'
import { LevelActiveType, TLevelData, TLevelsList } from '@fto/lib/drawing_interface/GraphicObjects'
import { TLineStyle, TLineStyleJSON } from '@fto/lib/drawing_interface/vclCanvas'
import { StrsConv } from '@fto/lib/ft_types/common/StrsConv'

function calcLevels(fibo: number[], defaultColors: string[] | null = null, decimals = 3, precision = 5): string {
    const levels = new TLevelsList()
    let lineStyle = new TLineStyle(ColorHelperFunctions.MakeColor(DelphiColors.clGreen), TPenStyle.psSolid, 1)
    for (const [i, levelValue] of fibo.entries()) {
        if (defaultColors) {
            lineStyle = new TLineStyle(defaultColors[i], TPenStyle.psSolid, 1)
        }
        const levelText = StrsConv.StrDouble(levelValue, decimals)
        const levelData = TLevelData.Create_TLevelData_Style(levelValue, levelText, lineStyle, LevelActiveType.active)
        levels.Add(levelData)
    }
    return levels.ExportToStr(precision)
}

export type SupportedPaintTools = keyof ToolProperties

export type ToolProperties = Omit<
    ToolPropertiesMap,
    PaintToolNames.ptMeasure | PaintToolNames.ptRiskRewardBuy | PaintToolNames.ptRiskRewardSell
>

const defaultTemplates: ToolProperties = {
    [PaintToolNames.ptGannBox]: {
        toolName: PaintToolNames.ptGannBox,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels(
            [0, 0.25, 0.382, 0.5, 0.618, 0.75, 1],
            ['#d1d1d1', '#808000', '#30755b', '#008000', '#9a5e5e', '#254883', '#d1d1d1']
        ),
        fShouldFillInside: true
    },
    [PaintToolNames.ptHLine]: {
        toolName: PaintToolNames.ptHLine,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptVLine]: {
        toolName: PaintToolNames.ptVLine,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptTrendLine]: {
        toolName: PaintToolNames.ptTrendLine,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptRay]: {
        toolName: PaintToolNames.ptRay,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptPolyline]: {
        toolName: PaintToolNames.ptPolyline,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptText]: {
        toolName: PaintToolNames.ptText,
        text: {
            color: '#000000',
            size: 20,
            style: 'normal',
            weight: 'regular'
        }
    },
    [PaintToolNames.ptAndrewsPitchfork]: {
        toolName: PaintToolNames.ptAndrewsPitchfork,
        baseLine: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        line1: {
            color: '#32CD32',
            style: TPenStyle.psSolid,
            width: 1
        },
        line2: {
            color: '#FFFF00',
            style: TPenStyle.psSolid,
            width: 1
        },
        medianLine: {
            color: '#008B8B',
            style: TPenStyle.psSolid,
            width: 1
        }
    },
    [PaintToolNames.ptRectangle]: {
        toolName: PaintToolNames.ptRectangle,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fillingStyle: {
            color: '#2F80ED',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        fShouldFillInside: true,
        extendLeft: false,
        extendRight: false
    },
    [PaintToolNames.ptEllipse]: {
        toolName: PaintToolNames.ptEllipse,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fillingStyle: {
            color: '#2F80ED',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        fShouldFillInside: true
    },
    [PaintToolNames.ptTriangle]: {
        toolName: PaintToolNames.ptTriangle,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fillingStyle: {
            color: '#2F80ED',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        fShouldFillInside: true
    },
    [PaintToolNames.ptRiskReward]: {
        toolName: PaintToolNames.ptRiskReward,
        profit: {
            fill: {
                color: '#99FF994c',
                opacity: 0.3,
                style: TBrushStyle.bsSolid
            },
            line: {
                color: '#01B2914c',
                width: 1,
                style: TPenStyle.psSolid
            }
        },
        loss: {
            fill: {
                color: '#FF8B894c',
                opacity: 0.3,
                style: TBrushStyle.bsSolid
            },
            line: {
                color: '#FF48464c',
                width: 1,
                style: TPenStyle.psSolid
            }
        },
        text: {
            color: '#ffffff',
            size: 12,
            style: 'normal',
            weight: 'regular'
        },
        showText: false,
        risk: 10,
        calculateFrom: TCalcFrom.cf_Balance
    },
    [PaintToolNames.ptFiboChannel]: {
        toolName: PaintToolNames.ptFiboChannel,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([38.2, 61.8, 100, 161.8, 261.8]),
        rays: true
    },
    [PaintToolNames.ptRegressionChannel]: {
        toolName: PaintToolNames.ptRegressionChannel,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        rays: false
    },
    [PaintToolNames.ptFiboRetracement]: {
        toolName: PaintToolNames.ptFiboRetracement,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([0, 23.6, 38.2, 50, 61.8, 78.6, 100, 127.2, 161.8, 261.8])
    },
    [PaintToolNames.ptFiboArc]: {
        toolName: PaintToolNames.ptFiboArc,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([38.2, 50, 61.8])
    },
    [PaintToolNames.ptFiboFan]: {
        toolName: PaintToolNames.ptFiboFan,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([38.2, 50, 61.8])
    },
    [PaintToolNames.ptFiboTimeZones]: {
        toolName: PaintToolNames.ptFiboTimeZones,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([0, 1, 2, 5, 8, 13, 21, 34])
    },
    [PaintToolNames.ptFiboExtension]: {
        toolName: PaintToolNames.ptFiboExtension,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        levels: calcLevels([0, 23.6, 38.2, 50, 61.8, 78.6, 100, 127.2, 161.8, 261.8])
    },
    [PaintToolNames.ptExtPriceLabel]: {
        toolName: PaintToolNames.ptExtPriceLabel,
        text: {
            color: '#2F80ED',
            size: 16,
            style: 'normal',
            weight: 'regular'
        },
        fill: {
            color: '#ffffff',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        line: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fShouldFillInside: true
    },
    [PaintToolNames.ptPriceLabelRight]: {
        toolName: PaintToolNames.ptPriceLabelRight,
        text: {
            color: '#2F80ED',
            size: 16,
            style: 'normal',
            weight: 'regular'
        },
        fill: {
            color: '#ffffff',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        line: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fShouldFillInside: true
    },
    [PaintToolNames.ptPriceLabelLeft]: {
        toolName: PaintToolNames.ptPriceLabelLeft,
        text: {
            color: '#2F80ED',
            size: 16,
            style: 'normal',
            weight: 'regular'
        },
        fill: {
            color: '#ffffff',
            opacity: 0.5,
            style: TBrushStyle.bsSolid
        },
        line: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        fShouldFillInside: true
    },
    [PaintToolNames.ptSign]: {
        toolName: PaintToolNames.ptSign,
        size: 54,
        fill: {
            color: '#2F80ED',
            opacity: 1,
            style: TBrushStyle.bsSolid
        }
    },
    [PaintToolNames.ptElliottWaveSymbolsImpulse]: {
        toolName: PaintToolNames.ptElliottWaveSymbolsImpulse,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        waveType: 'Impulse',
        waveStyle: 'RomanUpper',
        font: {
            size: 14,
            color: '#2F80ED',
            style: 'normal',
            weight: 'regular'
        },
        isPaintLineBetweenPoints: true
    },
    [PaintToolNames.ptElliottWaveSymbolsIndefiniteStart]: {
        toolName: PaintToolNames.ptElliottWaveSymbolsIndefiniteStart,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        waveType: 'IndefiniteStart',
        waveStyle: 'MixedWithParentheses',
        font: {
            size: 14,
            color: '#2F80ED',
            style: 'normal',
            weight: 'regular'
        },
        isPaintLineBetweenPoints: true
    },
    [PaintToolNames.ptElliottWaveSymbolsComplexCorrection]: {
        toolName: PaintToolNames.ptElliottWaveSymbolsComplexCorrection,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        waveType: 'ComplexCorrection',
        waveStyle: 'UpperCaseLetters',
        font: {
            size: 14,
            color: '#2F80ED',
            style: 'normal',
            weight: 'regular'
        },
        isPaintLineBetweenPoints: true
    },
    [PaintToolNames.ptElliottWaveSymbolsSimpleCorrection]: {
        toolName: PaintToolNames.ptElliottWaveSymbolsSimpleCorrection,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 1
        },
        waveType: 'SimpleCorrection',
        waveStyle: 'UpperCaseLetters',
        font: {
            size: 14,
            color: '#2F80ED',
            style: 'normal',
            weight: 'regular'
        },
        isPaintLineBetweenPoints: true
    },
    [PaintToolNames.ptBrush]: {
        toolName: PaintToolNames.ptBrush,
        lineStyle: {
            color: '#2F80ED',
            style: TPenStyle.psSolid,
            width: 2
        }
    }
}

export default defaultTemplates
