import { DelphiColors } from '@fto/lib/delphi_compatibility/DelphiBasicTypes'
import { DateUtils, TDateTime } from '../../delphi_compatibility/DateUtils'
import { NotImplementedError } from '../../utils/common_utils'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import { ELockToOption } from './OptionsEnums'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'

export enum TOptTrailingStopType {
    ts_Immediate,
    ts_OnProfit
}

export class TDefOrderInfo {
    lot!: number
    StopLoss!: number
    TakeProfit!: number
    TS!: boolean
    TStype!: TOptTrailingStopType
    AtProfit!: number
    Stop!: number
    Step!: number
}

export class THotKeys {
    Pause!: number
    StepBackByBar!: number
    StepForwardByBar!: number
    StepForwardByTick!: number
    // ... other hotkeys declarations
    TestingSpeedUp!: number
    TestingSpeedDown!: number
    ChartAutoScale!: number
    // ... other hotkeys declarations
    keys: number[]

    constructor() {
        this.keys = new Array(72).fill(0)
    }
}

//do not export this class, only its instance can be exported
export class TOptions {
    MinChartHeightInPercent: number
    MinOscWindowHeightInPercent: number
    DefaultOscWindowHeightInPercent: number
    DefaultOscSplitterHeightInPixels: number
    ChildFormsMaximized!: boolean
    ScrollingSpeed: number
    MouseSensitivity: number
    MagneticMode: boolean
    MagnetCrossHair: boolean
    StrongMagnetMode: boolean
    StrongMagnetSensitivity: number
    MagnetSensitivity: number
    EnableStrategy: boolean
    CloseIfProfitBigger: number
    CloseIfProfitLower: number
    LastScreenShotNumber: number
    EnableBreakpoints: boolean
    FirstStart: boolean
    FirstTestStart: boolean
    AutoscrollHistoryJournal: boolean
    AffiliateInfo: string
    MarketOrderSettings: string
    PendingOrderSettings: string
    MarketOrderTS: string
    PendingOrderTS: string
    InterfaceLanguage: number
    SetPauseWhenOrder: boolean
    ShowMiddleMarkerForLines: boolean
    LastToolID: number
    OnTFChangeLockTo: number
    SetPauseOnProjectStart: boolean
    ConfirmInstallRewrite: boolean
    PaintThickerBars: boolean
    CloseByGapPriceOnSL: boolean
    DefaultTimeframes: string
    CrossHairOnAllCharts: boolean
    ScrollAllCharts: boolean
    OnScrollLockTo: ELockToOption
    CopyPaintToolsOnCharts: boolean
    ToolsLinkNumber: number
    ShowHistoryFilters: boolean
    ShowNewsFilters: boolean
    LastNewsUpdate: TDateTime
    SymbolTree: string
    OldControlsOn: boolean
    ProgramWasUpgraded: boolean
    MouseWheelForZoom: boolean
    ShowProfitChart: boolean
    ShowBalance: boolean
    ShowEquity: boolean
    ShowMargin: boolean
    ShowDrawdown: boolean
    MasterKey: string
    RegistrationKey: string
    HardwareID: string
    UserName: string
    UserEmail: string
    JustActivated: boolean
    DSServiceType: number
    DataSubscrUpgraded: boolean
    DataCollection: boolean
    ProjectName: string
    LastUpdateCheck: TDateTime
    AutoCheckForUpdates: boolean
    AskPermissionToCheckUpdate: boolean
    AutoUpdatePeriod: number
    UpdateType: string
    UpdateAvailable: boolean
    UpdateVersion: string
    ProjectLoaded: boolean
    ProjectPath: string
    TestStarted: boolean
    Paused: boolean
    EditFieldActivated: boolean
    TableControlFocused: boolean
    SymbolListLoaded: boolean
    EduCourseActive: boolean
    HardwareIDChanged: boolean
    RibbonStarted: boolean
    DesktopIsLoading: boolean
    TempMagnetMode: boolean
    TempRotateMode: boolean
    ContinueWaveSymbolSequence: boolean
    ss_Area: number
    ss_ImageType: number
    ss_SaveToFile: boolean
    ss_CopyToClipboard: boolean
    ShowHints: boolean
    ShowNotification: boolean[]
    HotKeys: THotKeys
    PriceAxisFontSize = 12
    VERTICAL_GRID_FONT = `${this.PriceAxisFontSize}px Roboto Flex`
    DateScaleFontSize = 12
    DateScaleFont = `${this.DateScaleFontSize}px Roboto Flex`
    ShowOpenOrderMarker: boolean
    ShowCloseOrderMarker: boolean
    public isSyncActionsWithGraphTools: boolean = true

    constructor() {
        this.MinChartHeightInPercent = 10
        this.MinOscWindowHeightInPercent = 1
        this.DefaultOscWindowHeightInPercent = 12
        this.DefaultOscSplitterHeightInPixels = 4
        this.EnableStrategy = true
        this.CloseIfProfitBigger = 0
        this.CloseIfProfitLower = 0
        this.LastToolID = DateUtils.Now()
        this.LastScreenShotNumber = 1
        this.EnableBreakpoints = false
        this.FirstStart = false
        this.FirstTestStart = false
        this.AutoscrollHistoryJournal = true
        this.AffiliateInfo = ''
        this.MarketOrderSettings = ''
        this.PendingOrderSettings = ''
        this.MarketOrderTS = ''
        this.PendingOrderTS = ''
        this.InterfaceLanguage = 0
        this.SetPauseWhenOrder = false
        this.ShowMiddleMarkerForLines = false
        this.ScrollingSpeed = 1
        this.MouseSensitivity = 10 // increased for a larger hitbox (default 5)
        this.MagneticMode = true
        this.StrongMagnetMode = false
        this.StrongMagnetSensitivity = 200
        this.MagnetCrossHair = true
        this.MagnetSensitivity = 5
        this.OnTFChangeLockTo = ELockToOption.lt_RightBar
        this.SetPauseOnProjectStart = false
        this.ConfirmInstallRewrite = true
        this.PaintThickerBars = false
        this.CloseByGapPriceOnSL = false
        this.DefaultTimeframes = '15'
        this.CrossHairOnAllCharts = true
        this.ScrollAllCharts = true
        this.OnScrollLockTo = 1
        this.CopyPaintToolsOnCharts = true
        this.ToolsLinkNumber = Date.now()
        this.ShowHistoryFilters = false
        this.ShowNewsFilters = false
        this.LastNewsUpdate = DateUtils.EncodeDate(2000, 1, 1)
        this.SymbolTree = 'majors,crosses,commodities,metals,exotic,indexes,stocks,crypto'
        this.OldControlsOn = false
        this.ProgramWasUpgraded = false
        this.DataCollection = false
        this.MouseWheelForZoom = true
        this.TempRotateMode = false

        this.ShowProfitChart = true
        this.ShowBalance = true
        this.ShowEquity = true
        this.ShowMargin = true
        this.ShowDrawdown = true

        this.MasterKey = ''
        this.RegistrationKey = ''
        this.HardwareID = ''
        this.UserName = ''
        this.UserEmail = ''
        this.JustActivated = false
        this.DSServiceType = 0
        this.DataSubscrUpgraded = false

        this.ProjectName = ''

        this.ContinueWaveSymbolSequence = true

        this.ss_Area = 0
        this.ss_ImageType = 3
        this.ss_SaveToFile = true
        this.ss_CopyToClipboard = true

        // Temporary flags
        this.ProjectLoaded = false
        this.ProjectPath = ''
        this.TestStarted = false
        this.Paused = false
        this.EditFieldActivated = false
        this.TableControlFocused = false
        this.SymbolListLoaded = false
        this.EduCourseActive = false
        this.HardwareIDChanged = false
        this.RibbonStarted = false
        this.DesktopIsLoading = false
        this.TempMagnetMode = false

        this.LastUpdateCheck = DateUtils.Now()
        this.AutoCheckForUpdates = true
        this.AskPermissionToCheckUpdate = true
        this.AutoUpdatePeriod = 0
        this.UpdateType = 'normal'
        this.UpdateAvailable = false
        this.UpdateVersion = ''

        this.ShowHints = false
        this.ShowNotification = new Array(20).fill(true)

        // Initialize hotkeys
        this.HotKeys = new THotKeys()

        this.ShowOpenOrderMarker = true
        this.ShowCloseOrderMarker = true

        //FIXME: ... other hotkey initializations
    }

    getDefault(): TOptions {
        return new TOptions()
    }

    reset() {
        const defaultOptions = this.getDefault()
        this.copyFrom(defaultOptions)
    }

    copyFrom(other: TOptions) {
        Object.assign(this, other)
    }

    Clone(): TOptions {
        const cloneObj = this.getDefault()
        cloneObj.copyFrom(this)
        return cloneObj
    }

    ApplyOptions(options: TOptions) {
        Object.assign(this, options)
    }

    Save() {
        StrangeSituationNotifier.NotifyAboutUnexpectedSituation('TOptions.Save not implemented')
    }

    setAxisFontSize(size: number) {
        this.setPriceAxisFontSize(size)
        this.setDateTimeScaleFontSize(size)
    }

    setPriceAxisFontSize(size: number) {
        this.PriceAxisFontSize = size
        this.VERTICAL_GRID_FONT = `${size}px Roboto Flex`
        GlobalChartsController.Instance.recalculateRightMargins()
    }

    IsShowHint(id: number, always = false): boolean {
        throw new NotImplementedError('TOptions.IsShowHint')
    }

    setDateTimeScaleFontSize(size: number) {
        this.DateScaleFontSize = size
        this.DateScaleFont = `${size}px Roboto Flex`
        GlobalChartsController.Instance.recalculateDateTimeBarSize()
    }
}

export function GetOrderInfo(s: string, info: TDefOrderInfo): boolean {
    throw new NotImplementedError('GetOrderInfo')
}
