import { makeAutoObservable } from 'mobx'
import { collectGraphToolValues } from '@fto/lib/utils/collectGraphToolValues'

export type OptionType = {
    key: string
    value: any
    label: string
    type: string
    withOpacity?: boolean
    opacityValue?: number
    opacityOptionKey?: string
    min?: number
    max?: number
    step?: number
    isOptional?: boolean
    withLabel?: boolean
    order?: number
    selectOptions?: { value: string | number; label?: string; localeKey?: string }[]
    isActive?: boolean
}
export type SettingsType = {
    [id: string]: OptionType
}

const initialSettings = {}

class GraphToolStore {
    toolSettings: SettingsType

    constructor() {
        makeAutoObservable(this)

        this.toolSettings = initialSettings
        this.updateToolSettings = this.updateToolSettings.bind(this)
        this.resetToolSettings = this.resetToolSettings.bind(this)
        this.getKeyValueData = this.getKeyValueData.bind(this)
    }

    // The argument can be either an updater function or a PassedSettingsType object
    updateToolSettings(updater: ((prevSettings: SettingsType) => SettingsType) | SettingsType) {
        if (typeof updater === 'function') {
            // If updater is a function, call it with the current settings to get the updates
            this.toolSettings = {
                ...this.toolSettings,
                ...updater(this.toolSettings)
            }
        } else {
            // If updater is an object, directly apply the updates
            this.toolSettings = {
                ...this.toolSettings,
                ...updater
            }
        }
    }

    resetToolSettings() {
        this.toolSettings = initialSettings
    }

    getKeyValueData() {
        return collectGraphToolValues(this.toolSettings)
    }
}

export default new GraphToolStore()
